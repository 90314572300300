import { useRouteMatch } from "react-router-dom";
import SharedGlobalStyle from "shared/components/GlobalStyle";
import VUIGlobalStyle from "vui/styles";

export const GlobalStyle = () => {
  const canvasRoute = useRouteMatch({
    path: "/brands/:brandID/canvas",
    exact: true,
  });

  if (canvasRoute) {
    return <VUIGlobalStyle />;
  }

  return <SharedGlobalStyle />;
};

export default GlobalStyle;
