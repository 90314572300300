import { DefaultPrivacyLevel, INTAKE_SITE_US1 } from "@datadog/browser-core";

export default {
  CONFIG: {
    DEFAULT_PRIVACY_LEVEL: DefaultPrivacyLevel.MASK_USER_INPUT,
    SITE: INTAKE_SITE_US1,
    SESSION_SAMPLE_RATE: 100,
    SESSION_REPLAY_SAMPLE_RATE: 100,
    TRACK_USER_INTERACTIONS: true,
  },
  IGNORE_ERRORS: [
    "console error: [analytics.js] Failed to load Analytics.js",
    "console error: ChunkLoadError: Loading chunk", // Cut short to include different chunk positions
    "console error: Error: Failed to fetch",
    'Expected application/json content type but got "image/gif"', // LaunchDarkly requests blocked by browser
    "network error (Error)", // LaunchDarkly requests blocked by browser
    "Object Not Found Matching Id:", // Cut short to include different Ids
    "Reached max replay events size queued for upload", // Datadog cannot report session replay events at this time
    "ResizeObserver loop completed with undelivered notifications.",
    "ResizeObserver loop limit exceeded",
    "The operation was aborted.",
    "The play() request was interrupted by a call to pause().",
    "The play() request was interrupted because the media was removed from the document.",
    '"isTrusted": true', // LaunchDarkly requests blocked by browser
  ],
};
