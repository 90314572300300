import brandsRoutes from "internal/brands/routes";
import creatorSelectionRoutes from "internal/creatorSelection/routes";
import creatorsRoutes from "internal/creators/routes";
import { default as curationRoutes } from "internal/curation/routes";
import { default as inspoRoutes } from "internal/inspo/routes";
import paymentsRoutes from "internal/payments/routes";
import {
  default as briefingRoutes,
  default as projectsRoutes,
} from "internal/projects/routes";
import showreelRoutes from "internal/showreel/routes";
import systemRoutes from "internal/system/routes";
import { IRouteGroup, IRouteProps } from "shared/types/routes";

const routes: IRouteProps[] = [];

// Build app routes paths, ensure systemRoutes is last element in array
[
  brandsRoutes,
  briefingRoutes,
  creatorSelectionRoutes,
  creatorsRoutes,
  curationRoutes,
  inspoRoutes,
  paymentsRoutes,
  projectsRoutes,
  showreelRoutes,
  systemRoutes,
].forEach((feature: IRouteGroup) => {
  feature.routes.forEach((route: IRouteProps) => {
    routes.push({
      ...route,
      path: `/${feature.name}${route.path}`,
    });
  });
});

export default routes;
