import { lazy } from "react";
import { IRouteGroup } from "shared/types/routes";

const Brands = lazy(() => import("./pages/Brands"));
const Briefs = lazy(() => import("./pages/Briefs"));
const Canvas = lazy(() => import("./pages/Canvas"));

export const routes: IRouteGroup = {
  name: "brands",
  routes: [
    {
      authRequired: true,
      component: Brands,
      exact: true,
      path: "",
    },
    {
      authRequired: true,
      component: Briefs,
      exact: true,
      path: "/:brandID/briefs",
    },
    {
      authRequired: true,
      component: Canvas,
      exact: true,
      fullscreen: true,
      path: "/:brandID/canvas",
    },
  ],
};

export default routes;
