import { ApolloError } from "@apollo/client";
import {
  BrandFieldsFragment as IBrand,
  MegaSearchBriefFieldsFragment as IBrief,
} from "internal/shared/types/graphql-api";
import BrandSearchTile from "internal/system/components/BrandSearchTile";
import BriefSearchTile from "internal/system/components/BriefSearchTile";
import {
  Container,
  Error,
  Loading,
  Results,
  StyledSearchBar,
} from "internal/system/components/MegaSearch/MegaSearchComponents";
import SearchResultGroup from "internal/system/components/SearchResultGroup";
import ANALYTICS from "internal/system/constants/analytics";
import IDS from "internal/system/constants/ids";
import textualize from "internal/system/utils/textualize";
import {
  ChangeEvent,
  HTMLAttributes,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import KEYBOARD_SHORTCUTS from "shared/constants/shortcuts/keyboardShortcuts";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";

export const MAX_PER_GROUP = 20;

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  brands?: IBrand[];
  briefs?: IBrief[];
  error?: ApolloError;
  hasMoreBrands: boolean;
  hasMoreBriefs: boolean;
  id: string;
  loading?: boolean;
  onSearchTermChange?: (term: string) => void;
}

export const MegaSearch = ({
  brands,
  briefs,
  className,
  error,
  hasMoreBrands,
  hasMoreBriefs,
  id,
  loading,
  onSearchTermChange,
}: IProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPrimed, setSearchPrimed] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>();
  const activeRef = useRef<HTMLAnchorElement>(null);

  const updateSearchTerm = useCallback(
    (term: string) => {
      setSearchTerm(term);

      if (onSearchTermChange) {
        onSearchTermChange(term);
      }
    },
    [onSearchTermChange],
  );

  let TOTAL_RESULTS = 0;

  if (brands) {
    TOTAL_RESULTS += brands.length;
  }
  if (briefs) {
    TOTAL_RESULTS += briefs.length;
  }

  let timeoutId = 0;

  // `onContainerBlur`/`onContainerFocus` keep the search open if focus remains within this component
  // React bubbles blur/focus events, meaning we get a blur + focus each time focus shifts from child to child
  // `setTimeout` stops closure from happening if its immediately followed by a focus
  const onContainerBlur = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeoutId = window.setTimeout(() => {
      setOpen(false);
    }, 0);
  }, []);

  const onContainerFocus = useCallback(() => {
    window.clearTimeout(timeoutId);
  }, [timeoutId]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      updateSearchTerm(value);

      if (value !== "") {
        setSearchPrimed(true);
      } else {
        setSearchPrimed(false);
      }
    },
    [updateSearchTerm],
  );

  const onSearchPerformed = useCallback(() => {
    if (searchPrimed) {
      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.MEGA_SEARCH,
        ANALYTICS.EVENTS.SEARCH_PERFORMED,
        {
          results: {
            brands: brands ? brands.length : 0,
            briefs: briefs ? briefs.length : 0,
            total: (brands ? brands.length : 0) + (briefs ? briefs.length : 0),
          },
          searchTerm,
        },
      );

      setSearchPrimed(false);
    }
  }, [brands, briefs, searchPrimed, searchTerm]);

  const onClear = useCallback(() => {
    onSearchPerformed();
    updateSearchTerm("");
  }, [onSearchPerformed, updateSearchTerm]);

  const onInputFocus = useCallback(() => {
    setOpen(true);
    setActiveIndex(undefined);
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case KEYBOARD_SHORTCUTS.ARROW_DOWN:
          event.preventDefault();
          setActiveIndex((current) => {
            let newIndex;
            if (current === undefined) {
              newIndex = 0;
            } else {
              newIndex = current + 1;
            }
            if (newIndex > TOTAL_RESULTS - 1) {
              return current;
            } else {
              return newIndex;
            }
          });
          break;
        case KEYBOARD_SHORTCUTS.ARROW_UP:
          event.preventDefault();
          setActiveIndex((current) => {
            let newIndex;
            if (current === undefined) {
              // Don't enter results
              return current;
            } else {
              newIndex = current - 1;
            }
            if (newIndex < 0) {
              return undefined;
            } else {
              return newIndex;
            }
          });
          break;
        case KEYBOARD_SHORTCUTS.ENTER:
          if (event.target === ref.current) {
            event.preventDefault();
            setActiveIndex(0);
          }
          break;
        case KEYBOARD_SHORTCUTS.ESCAPE:
          onClear();
          break;
      }
    },
    [TOTAL_RESULTS, onClear],
  );

  const onSearchResultClicked = useCallback(
    (type: string, position: number, resultInfo: { [k: string]: string }) =>
      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.MEGA_SEARCH,
        ANALYTICS.EVENTS.SEARCH_RESULT_CLICKED,
        {
          position,
          results: {
            brands: brands ? brands.length : 0,
            briefs: briefs ? briefs.length : 0,
            total: (brands ? brands.length : 0) + (briefs ? briefs.length : 0),
          },
          searchTerm,
          type,
          ...resultInfo,
        },
      ),
    [brands, briefs, searchTerm],
  );

  useEffect(() => {
    if (!open) {
      setActiveIndex(undefined);
    } else if (activeIndex === undefined) {
      ref.current!.focus();
    }
  }, [activeIndex, open]);

  useEffect(() => {
    if (activeRef.current) {
      activeRef.current.focus();
    }

    const trackSearchPerformed = window.setTimeout(() => {
      onSearchPerformed();
    }, 500);

    return () => clearTimeout(trackSearchPerformed);
  }, [activeIndex, onSearchPerformed]);

  return (
    <Container
      className={className}
      onBlur={onContainerBlur}
      onFocus={onContainerFocus}
      onKeyDown={onKeyDown}
      role="search"
      tabIndex={-1} // Keeps focus within search when clicking container
    >
      <StyledSearchBar
        id={id}
        onChange={onChange}
        onClear={onClear}
        onFocus={onInputFocus}
        ref={ref}
        value={searchTerm}
      />
      {open && (brands || briefs || error || loading) && (
        <Results>
          {loading && <Loading />}
          {error && <Error>{textualize("megaSearch.error")}</Error>}
          {!loading && !error && brands && (
            <SearchResultGroup
              count={brands.length}
              id={IDS.MEGA_SEARCH.BRAND_RESULTS}
              moreAvailable={hasMoreBrands}
              name={textualize("megaSearch.brands")}
            >
              {brands.map((brand, index) => (
                <li key={brand.id}>
                  <BrandSearchTile
                    brandID={brand.id}
                    imageURI={brand.imageURI}
                    inactive={
                      activeIndex === undefined
                        ? undefined
                        : activeIndex !== index
                    }
                    name={brand.name}
                    onClick={() =>
                      onSearchResultClicked("brand", index + 1, {
                        name: brand.name,
                      })
                    }
                    onMouseEnter={() => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(undefined)}
                    ref={activeIndex === index ? activeRef : undefined}
                    searchTerm={searchTerm}
                    tabIndex={-1}
                    to={`/brands/${brand.id}/briefs`}
                  />
                </li>
              ))}
            </SearchResultGroup>
          )}

          {!loading && !error && briefs && (
            <SearchResultGroup
              count={briefs.length}
              id={IDS.MEGA_SEARCH.BRIEF_RESULTS}
              moreAvailable={hasMoreBriefs}
              name={textualize("megaSearch.projects")}
            >
              {briefs.map((brief, index) => (
                <li key={brief.id}>
                  <BriefSearchTile
                    briefID={brief.id}
                    imageURI={brief.brand.imageURI}
                    inactive={
                      activeIndex === undefined
                        ? undefined
                        : activeIndex !== index + (brands ? brands.length : 0)
                    }
                    jobReference={brief.jobReference}
                    onClick={() =>
                      onSearchResultClicked("brief", index + 1, {
                        projectName: brief.clientBriefName!,
                        jobReference: brief.jobReference,
                        shortHash: brief.shortHash,
                        title: brief.title,
                      })
                    }
                    onMouseEnter={() =>
                      setActiveIndex(index + (brands ? brands.length : 0))
                    }
                    onMouseLeave={() => setActiveIndex(undefined)}
                    projectName={brief.clientBriefName!}
                    ref={
                      activeIndex === index + (brands ? brands.length : 0)
                        ? activeRef
                        : undefined
                    }
                    searchTerm={searchTerm}
                    shortHash={brief.shortHash}
                    tabIndex={-1}
                    title={brief.title}
                    to={`/curation/${brief.shortHash}/videos`}
                  />
                </li>
              ))}
            </SearchResultGroup>
          )}
        </Results>
      )}
    </Container>
  );
};

export default MegaSearch;
