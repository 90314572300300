import { lazy } from "react";
import { IRouteGroup } from "shared/types/routes";
import { Redirect, useParams } from "react-router-dom";

const CreatorManagement = lazy(() => import("./pages/CreatorManagement"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Downloads = lazy(() => import("./pages/Downloads"));
const Login = lazy(() => import("./pages/Login"));
const LoginCallback = lazy(() => import("./pages/LoginCallback"));

// Brands are now in their own subdirectory
export const BrandBriefsRedirect = () => {
  const { brandID } = useParams<{
    brandID: string;
  }>();

  return <Redirect to={`/brands/${brandID}/briefs`} />;
};

export const routes: IRouteGroup = {
  name: "",
  routes: [
    {
      authRequired: true,
      component: BrandBriefsRedirect,
      exact: true,
      path: ":brandID/briefs",
    },
    {
      authRequired: true,
      component: CreatorManagement,
      path: "creator-management/:briefShortHash",
    },
    {
      authRequired: true,
      component: Downloads,
      fullscreen: true,
      path: "downloads/:token",
    },
    {
      component: Login,
      exact: true,
      path: "login",
    },
    {
      component: LoginCallback,
      exact: true,
      path: "login/callback",
    },
    {
      authRequired: true,
      component: Dashboard,
      exact: true,
      path: "",
    },
  ],
};

export default routes;
