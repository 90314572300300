import creatorManagement from "./creatorManagement.yml";
import dashboard from "./dashboard.yml";
import loginPage from "./loginPage.yml";
import megaSearch from "./megaSearch.yml";

export const copy = {
  creatorManagement,
  dashboard,
  loginPage,
  megaSearch,
};

export default copy;
