export default {
  CATEGORIES: {
    CREATOR_MANAGEMENT: "CreatorManagement",
    DASHBOARD: "Dashboard",
    DOWNLOADS: "Downloads",
    LOGIN: "Login",
    MEGA_SEARCH: "MegaSearch",
  },
  EVENTS: {
    BATCH_DOWNLOAD_ERROR: "BatchDownloadError",
    BATCH_DOWNLOAD_SUCCESS: "BatchDownloadSuccess",
    COMPLETED_PROJECTS_SORTED: "CompletedProjectsSorted",
    LOGIN_ERROR: "LoginError",
    LOGIN_SUCCESS: "LoginSuccess",
    OPEN_PROJECTS_SORTED: "OpenProjectsSorted",
    PAYMENTS_SAVED: "PaymentsSaved",
    SEARCH_PERFORMED: "SearchPerformed",
    SEARCH_RESULT_CLICKED: "SearchResultClicked",
    VIEW_ALL_BRIEFS_TOGGLED: "ViewAllBriefsToggled",
  },
};
